import styled from 'styled-components';
import { ThemeType } from 'types';
import { Container } from 'blocks/Block/styles';
import { HeroContainer, HeroWrapper } from 'blocks/Hero/styles';
import ToggleButtonsWrapper, { OptionButton } from '../ToggleButtons/styles';

export const StyledFilterHeader = styled.div(
  ({ theme: { colors, spacing, mobileView } }: ThemeType) => `

  `,
);

export const StyledFilterButtonsHeader = styled.div(
  ({ theme: { mobileView, spacing, fontFamilies } }: ThemeType) => `
    padding: ${spacing.x10} 0 ${spacing.x1};
    z-index: 10;
    
    > section {
      padding: 0 !important;
    }

    ${Container} {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between;
      align-items: center;
    }

    ${HeroContainer} {
      width: auto !important;
      flex: none !important;
      
      h1 {
        font-family: ${fontFamilies.heading};
      }
    }

    ${ToggleButtonsWrapper} {
      flex: 1;
      text-align: right;
    }

    ${OptionButton} {
      margin-right: 0 !important;
      margin-left: ${spacing.x3};
    }

    ${mobileView} {
      padding-bottom: 0;
      ${Container} {
        flex-direction: column !important;
        align-items: flex-start;
      }

      ${ToggleButtonsWrapper} {
        margin-top: ${spacing.x1};
        text-align: left;
      }

      ${OptionButton} {
        margin-left: 0 !important;
        margin-right: ${spacing.x2} !important;
      }
    }
`,
);

export const StyledFilterSidebar = styled.div(
  ({ theme: { colors, spacing, mobileView } }: ThemeType) => `
    text-align: left;
    border-radius: 0 88px 0 0;
    background-color: ${colors.white};
    padding: ${spacing.x2} ${spacing.x4} ${spacing.x1} ${spacing.x2};
    margin-bottom: ${spacing.x2};
    box-shadow: 8px 8px 7px 0 rgba(10, 4, 5, 0.08);
    
    .filter-link {
      cursor: pointer;
      color: ${colors.eggplant}
      padding: ${spacing.x1} 0;
      border-bottom: 1px solid ${colors.pink};
      display: block;
      text-transform: uppercase;
      font-weight: 900;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover, &.active {
        color: ${colors.darkishPink};
      }
    }

    ${mobileView} {
      display: none;
    }
  `,
);

export const StyledFilterContent = styled.div(
  ({ theme: { mobileView } }: ThemeType) => `
    > *:first-child {
      padding-top: 0;
    }
    ${mobileView} {
      width: 100%;
    }
`,
);

export const InputContainer = styled.div(
  ({ isFocused, theme: { colors, spacing } }: ThemeType & { isFocused: boolean }) => `
    margin: ${spacing.x2} auto 0;
    align-items: stretch;
    background-color: ${isFocused ? colors.white : colors.white};
    display: flex;
    max-width: 680px;
    overflow: hidden;
    width: 100%;
    transition: background .3s ease-in-out;

    button {
      align-self: center;
      pointer-events: none;
      color: ${colors.white};
      opacity: ${isFocused ? 1 : 0.7};

      svg {     
        color: ${colors.black};
        height: 22px;
        width: 22px;
        transition: color .3s ease-in-out;
      }
    }

    input {
      appearance: none;
      outline: none;
      background: transparent;      
      border: none;
      box-shadow: none;
      color: ${colors.black};
      flex: 1 auto;
      font-size: 16px;
      height: 48px;
      padding-left: 16px;
      transition: color .3s ease-in-out;

      &::placeholder {
        color: ${colors.black};
      }
    }
  `,
);
