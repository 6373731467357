import React from 'react';
import AbstractPage from './AbstractPage';
import FixedLayout from './FixedLayout';

class FixedPage extends AbstractPage {
  render() {
    const { announcement, loaded, headless } = this.state;
    const { headerMenu, mainMenu, footerMenu, secondaryFooterMenu, footerTopPartners, footerSecondaryPartners, content } = this.props;

    const children = this.renderChildren();

    return (
      <FixedLayout
        headerMenu={mainMenu?.menuItems?.nodes && mainMenu.menuItems.nodes}
        mainMenu={mainMenu?.menuItems?.nodes && mainMenu.menuItems.nodes}
        footerMenu={footerMenu?.menuItems?.nodes && footerMenu.menuItems.nodes}
        secondaryFooterMenu={secondaryFooterMenu?.menuItems?.nodes && secondaryFooterMenu.menuItems.nodes}
        announcement={announcement}
        hideAnnouncement={this.hideAnnouncement}
        seo={content && content.seo}
        headless={headless}
        language={content.language}
      >
        {children}
      </FixedLayout>
    );
  }
}

export default FixedPage;
