import React from 'react';
import { AdSlot } from 'react-dfp';
import isBrowser from 'utils/isBrowser';
import isMobile from 'utils/isMobile';
import { HeroAdWrapper } from "./styles";

export const HeroAd: React.FunctionComponent<any> = (() => {
    if (!isBrowser()) {
        return null;
    }
    return (
        <HeroAdWrapper>
            {!isMobile() &&
                <div className="desktop-ads">
                    <AdSlot targetingArguments={{ pagepos: '501', page: location.pathname, href: location.href }} sizes={[[970, 250], [729, 90]]} />
                </div>
            }
            {isMobile() &&
                <div className="mobile-ads">
                    <AdSlot targetingArguments={{ pagepos: '501', page: location.pathname, href: location.href }} sizes={[[320, 50]]} />
                </div>
            }
        </HeroAdWrapper>
    )
});

export default HeroAd;