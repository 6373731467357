import axios from 'axios';
import { UserInterface } from '../types/Agents';
import { remove as removeLocalStorage } from '../utils/localStorage';
import { remove as removeSessionStorage } from '../utils/sessionStorage';

const request = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}/wp-json`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.response.use(
  (response) => response,
  async (e) => {
    const status = e.response ? e.response.status : null;

    // User must be delted
    if ([403, 401].includes(status)) {
      removeSessionStorage('token');
      removeLocalStorage('token');
      removeSessionStorage('user');
    }

    //  Retry
    if (status === 429) {
      return Promise.resolve(() => setTimeout(async () => await axios.request(e.config), e.response.headers['retry-after'] * 1000));
    }

    return Promise.reject(e);
  },
);

export default {
  instagramLogin: async (data: { token: string }) => request.post('/sitg/v1/m_instagram/login', data),
  facebookLogin: async (data: { token: string }) => request.post('/sitg/v1/m_facebook/login', data),
  updateContact: async ({ contact, token }: { contact: UserInterface; token: string }): Promise<{ data: UserInterface }> =>
    request.post('/jwt-auth/v1/user/current', contact, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getContact: async ({ token }: { token: string }): Promise<{ data: UserInterface }> =>
    request.get('/jwt-auth/v1/user/current', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getLineup: async () => request.get('/sitg/v1/artists/spotifyIds'),
};
