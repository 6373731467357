import React from 'react';
import styled from 'styled-components';
import ThemeType from 'types/Theme';
import {HeroAsssets} from 'blocks/Hero/styles';
import heroImageYellow from 'assets/images/hero-tear-green.png';
import headerShadow from 'assets/images/header-background-shadow.png';

const HeaderOuter = styled.div(
  ({ theme: { spacing, tabletView , colors} }: ThemeType) => `
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    padding-top: ${spacing.x1};
    background-color: ${colors.lime};
    color: ${colors.black};
    &:after {
      content: "";
      width: 100%;
      height: 32px;
      background-position: bottom center;
      background-image: url(${heroImageYellow});
      background-size: 812px 63px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 5;
    }
    
    &:before {
      content: "";
      width: 100%;
      height: 48px;
      background-position: bottom center;
      background-image: url(${headerShadow});
      background-size: 812px 63px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 0;
      opacity: 0.08;
      transform: translateY(8px);
    }
    ${tabletView} {
      padding: ${spacing.x4} ${spacing.x2} 0;
      margin-bottom: ${spacing.x5};
    }
    ${HeroAsssets} {
      display: none;
    }
`,
);

const HeaderInner = styled.div(
  ({ theme: { colors, mobileView, width } }: ThemeType) => `
    width: 100%;
    max-width: ${width.tablet};
    margin: 0 auto;
    position: relative;
`,
);

class Header extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <HeaderOuter>
        <HeaderInner>{children}</HeaderInner>
      </HeaderOuter>
    );
  }
}

export default Header;
