import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from 'components/Layout';
import Header from 'components/Header';
import MainMenu from 'components/MainMenu';
import SearchMenu from 'components/SearchMenu';
import SubscriptionMenu from 'components/SubscriptionMenu';
import { MODALS, MENUS, PAGE_WRAP_ID } from 'constants/layouts';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/theme';
import AbstractLayout from './AbstractLayout';
import Head from './Head';
import SubscribeConsumer, { SubscribeProvider } from 'context/SubscribeContext';

class FixedLayout extends AbstractLayout {
  render() {
    const { showMenu } = this.state;
    const { seo, announcement, hideAnnouncement, headerMenu, mainMenu, headless, language } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <SubscribeProvider>
          <SubscribeConsumer>
            {({ openedMenu, openSubscribeMenu, openMainMenu, openSearchMenu, closeMenu }) => (
              <>
                <Layout.Main>
                  <GlobalStyle />
                  <Head {...seo} lang={language ? language.code : 'en'} />
                  {!headless && (
                    <>
                      <MainMenu
                        isOpen={String(openedMenu) === MENUS.MAIN}
                        close={closeMenu}
                        openSubscribeMenu={openSubscribeMenu}
                        items={mainMenu}
                      />
                      <SearchMenu isOpen={String(openedMenu) === MENUS.SEARCH} close={closeMenu} />
                      <SubscriptionMenu isOpen={String(openedMenu) === MENUS.SUBSCRIBE} close={closeMenu} />
                      <Header
                        openMainMenu={openMainMenu}
                        openSearch={openSearchMenu}
                        openSubscribeMenu={openSubscribeMenu}
                        pageLinks={headerMenu}
                        announcement={announcement}
                        hideAnnouncement={hideAnnouncement}
                        hidePrimaryHeader={true}
                        language={language}
                      />
                    </>
                  )}
                  <Layout.Page id={PAGE_WRAP_ID}>{this.props.children}</Layout.Page>
                </Layout.Main>
              </>
            )}
          </SubscribeConsumer>
        </SubscribeProvider>
      </ThemeProvider>
    );
  }
}

export default FixedLayout;
