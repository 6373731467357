import styled from 'styled-components';
import ThemeType from 'types/Theme';

export default styled.div(
  ({ theme: { spacing, mobileView } }: ThemeType) => `
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    padding: ${spacing.x5} ${spacing.x8} ${spacing.x2};

    ${mobileView} {
      padding: 0 ${spacing.x2};
    }
`,
);
