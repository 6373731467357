import React from 'react';
import { ArtistType } from 'types';
import Artist from '../Artist';
import { scroller } from 'react-scroll';
import isBrowser from 'utils/isBrowser';
import { InView } from 'react-intersection-observer';

class ScrollArtist extends React.PureComponent<ArtistType> {
  constructor(props) {
    super(props);
    this.handleInView = this.handleInView.bind(this);
  }

  componentDidMount() {
    this.doScroll();
  }

  componentDidUpdate() {
    this.doScroll();
  }

  handleInView(inView) {
    if (inView) {
      const artistLinks = document.getElementsByClassName('lineup-link-active');

      Array.prototype.forEach.call(artistLinks, (artistLink) => {
        artistLink.classList.remove('lineup-link-active');
      });

      const activeLink = document.getElementById(`Link-${this.props.id}`);

      if (activeLink) {
        activeLink.classList.add('lineup-link-active');
      }
      if(isBrowser()){
        clearTimeout(window.lineupTimeout);
        
        window.lineupTimeout = setTimeout(() => {
          window.dataLayer = window.dataLayer || [];
          window.history.pushState(null, '', `${this.props.getBaseUrl()}${this.props.slug}`);
          window.dataLayer.push({
           'event': 'artistView',
           'artist': this.props.title
           });
        }, 3000);
        
      }

      scroller.scrollTo(`Link-${this.props.id}`, {
        offset: this.props.lineupOffset,
        duration: 500,
        containerId: 'links-container',
      });
    }
  }

  doScroll() {
    if (this.props.selected && this.props.containerId) {
        scroller.scrollTo(`Artist-${this.props.id}`, {
          offset: this.props.artistOffset,
          duration: 500,
          containerId: this.props.containerId,
        });
    }
  }

  render() {
    return (
      <InView threshold={0.2} as="div" onChange={this.handleInView}>
        <Artist {...this.props} />
      </InView>
    );
  }
}

export default ScrollArtist;
