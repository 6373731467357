import React from 'react';
import { StyledContentInner, StyledContent, StyledSection } from './styles';

const Fixed: React.FunctionComponent<any> = ({ children, isFirstSection, pageHasAnnouncement }) => (
  <StyledSection>
    <StyledContent isFirstSection={isFirstSection} pageHasAnnouncement={pageHasAnnouncement}>
      <StyledContentInner pageHasAnnouncement={pageHasAnnouncement}>{children}</StyledContentInner>
    </StyledContent>
  </StyledSection>
);

export default Fixed;
