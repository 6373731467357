import ColumnBlock from 'blocks/Block';
import styled from 'styled-components';

export const HeroAdWrapper = styled(ColumnBlock)(
    ({ theme: { spacing, mobileView } }) => `
      text-align: center;
      padding-top: ${spacing.x6};
      padding-bottom: ${spacing.x1};
      ${mobileView} {
        padding-top: ${spacing.x6};
        padding-bottom: ${spacing.x1};
      }
  `,
  );