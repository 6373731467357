import React from 'react';
import StyledWrapper, { OptionButton } from './styles';
import { TOGGLE_OPTIONS } from 'constants/lineup';

interface Props {
  options: string[];
  selectedOption: string;
  onChange?: (e) => void;
}

const ToggleButtons: React.StatelessComponent<Props> = ({ options, selectedOption, onChange }) => (
  <StyledWrapper>
    {options.map((option, id) => {
      const isSelected = option === selectedOption;

      return (
        <OptionButton
          key={id}
          mySplendour={option === TOGGLE_OPTIONS.MY_SPLENDOUR}
          selected={isSelected}
          disabled={isSelected}
          onClick={() => onChange && onChange(option)}
        >
          <span className="h7">{option}</span>
        </OptionButton>
      );
    })}
  </StyledWrapper>
);

export default ToggleButtons;
