import styled from 'styled-components';
import Layout from 'components/Layout';
import ThemeType from 'types/Theme';

export const StyledSection = styled(Layout.Section)(
  ({ theme: { spacing, mobileView, linearGradient } }: ThemeType) => `
    background: ${linearGradient.nightSky};
    position: relative;
    z-index: 4;
    height: 100vh;

    ${mobileView} {
      padding: 0 ${spacing.x2};
      height: auto;
    }
`,
);

export const StyledContent = styled(Layout.Content)`
  padding-top: 0 !important;
`;

interface ContentInnerType extends ThemeType {
  pageHasAnnouncement: boolean;
}

export const StyledContentInner = styled(Layout.ContentInner)(
  ({ theme: { mobileView }, pageHasAnnouncement }: ContentInnerType) => `
    ${pageHasAnnouncement ? 'padding-top: 60px;' : ''}
    height: 100vh;
    overflow: auto;

    ${mobileView} {
      height: auto;
      overflow: visible;
    }
`,
);
