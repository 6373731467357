import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import { HeroBlock } from 'blocks';
import { FilterHeaderType, FilterSidebarType, FilterTextInputHeaderType, FilterToggleButtonsHeaderType } from 'types/filter';
import { slugify } from 'utils/slugify';
import IconButton from '../IconButton';
import ToggleButtons from '../ToggleButtons';

import { StyledFilterContent, StyledFilterHeader, StyledFilterSidebar, StyledFilterButtonsHeader, InputContainer } from './style';
import { HEADER_OFFSET, ANNOUNCEMENT_OFFSET } from 'constants/offsets';
import isBrowser from 'utils/isBrowser';

const FilterHeader: React.FunctionComponent<FilterHeaderType> = ({ title, center = true, children }) => {
  return (
    <StyledFilterHeader>
      <HeroBlock title={title} center={center}>
        {children}
      </HeroBlock>
    </StyledFilterHeader>
  );
};

const FilterTextInputHeader: React.FunctionComponent<FilterTextInputHeaderType> = ({
  title,
  placeholder,
  value,
  onChange,
  pageHasAnnouncement,
}) => {
  if (!isBrowser()) {
    return null;
  }

  useEffect(() => {
    if (window.location.hash) {
      scroller.scrollTo(slugify(decodeURI(window.location.hash)), {
        offset: -(HEADER_OFFSET.default + (pageHasAnnouncement ? ANNOUNCEMENT_OFFSET.default : 0)),
        duration: 500,
        smooth: true,
      });
    }
  }, []);

  const [isFocused, setFocus] = useState(false);

  return (
    <FilterHeader title={title} isFocused={isFocused}>
      <InputContainer isFocused={isFocused}>
        <input
          type="text"
          value={value || ''}
          placeholder={placeholder || 'Search'}
          onChange={onChange}
          onBlur={() => setFocus(!isFocused)}
          onFocus={() => setFocus(!isFocused)}
        />
        <IconButton type="search" onClick={() => false} color='transparent' />
      </InputContainer>
    </FilterHeader>
  );
};

const FilterToggleButtonsHeader: React.FunctionComponent<FilterToggleButtonsHeaderType> = ({
  title,
  selectedOption,
  options,
  onChange,
}) => (
  <StyledFilterButtonsHeader>
    <HeroBlock title={title} center={false}>
      <ToggleButtons onChange={onChange} options={options} selectedOption={selectedOption} />
    </HeroBlock>
  </StyledFilterButtonsHeader>
);

const FilterSidebar: React.FunctionComponent<FilterSidebarType> = ({ items, onClick, pageHasAnnouncement }) =>
  items.length ? (
    <StyledFilterSidebar>
      {items.map(
        (value, key) =>
          value && (
            <span
              key={key}
              className="p5 filter-link"
              onClick={(e) => {
                onClick(e);
                setTimeout(
                  () =>
                    scroller.scrollTo(slugify(value), {
                      offset: -(HEADER_OFFSET.default + (pageHasAnnouncement ? ANNOUNCEMENT_OFFSET.default : 0)),
                      duration: 500,
                      smooth: true,
                    }),
                  250,
                );
                window.history.pushState(null, '', '#' + slugify(value));
              }}
              children={value}
            />
          ),
      )}
    </StyledFilterSidebar>
  ) : null;

export default {
  Header: FilterHeader,
  Sidebar: FilterSidebar,
  TextInputHeader: FilterTextInputHeader,
  ToggleButtonsHeader: FilterToggleButtonsHeader,
  Content: StyledFilterContent,
};
