import isBrowser from './isBrowser';

export const get = (item: string) => {
  if (!isBrowser()) return null;

  const data = window.sessionStorage.getItem(item);

  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};
export const set = (item: string, value: string) => isBrowser() && window.sessionStorage.setItem(item, value);
export const remove = (item: string) => isBrowser() && window.sessionStorage.removeItem(item);
