import React from 'react';
import { format } from 'date-fns';
import Artist from 'components/ScrollArtist';
import Filter from 'components/Filter';
import Lineup from 'components/Lineup';
import SideBySide from 'components/SideBySide';
import FixedPage from 'layout/components/FixedPage';
import FixedSection from 'sections/Fixed';
import { ArtistDetailsSection, LineupSection } from 'styles/components/LineupLayout';
import Image from 'types/Image';
import isMobile from 'utils/isMobile';
import isBrowser from 'utils/isBrowser';
import isHeadless from 'utils/isHeadless';
import onReady from 'utils/onReady';
import onReadyUnmount from 'utils/onReadyUnmount';
import { get as getLocalStorage } from 'utils/localStorage';
import { get as getSessionStorage, set as setSessionStorage } from 'utils/sessionStorage';
import { TOGGLE_OPTIONS } from 'constants/lineup';
import API from 'agents/API';
import HeroAd from 'components/HeroAd';
import { DFPManager } from 'react-dfp';
const generateArtistId = (id) => `Artist-${id}`;

class LineupPage extends FixedPage {
  
  static getDerivedStateFromProps({ artists, context, announcement }, prevState) {
    const derivedState = {
      ...prevState,
      headless: isHeadless(),
    };
    if (announcement && announcement.nodes.length > 0) {
      derivedState.announcement = announcement.nodes[0];
    }

    if (artists) {
      const selectedOption = prevState.selectedOption;

      if (selectedOption === TOGGLE_OPTIONS.ALL) {
        derivedState.artists = artists.nodes;
      } else {
        derivedState.artists = artists.nodes.filter(({ artistDetails }) => {
          return (
            artistDetails.sessionArtistRelationship &&
            artistDetails.sessionArtistRelationship.length &&
            artistDetails.sessionArtistRelationship.find(
              ({ sessionDetails: { sessionDate } }) => format(sessionDate, 'dddd') === selectedOption,
            )
          );
        });
      }
    }
    if (!prevState.hasInteracted && context?.selectedArtistWordpressId) {
      derivedState.isOpen = true;
    }

    return derivedState;
  }

  state = {
    artists: [],
    announcement: null,
    selectedOption: TOGGLE_OPTIONS.ALL,
    artistOffset: 0,
    lineupOffset: 0,
    hasInteracted: false,
    isOpen: false,
    loaded: false,
    headless: false,
    showModal: false,
    timeout: null,
  };

  private headerRef;
  private scrollViewRef;

  constructor(props) {
    super(props);
    this.determineOffsets = this.determineOffsets.bind(this);
    this.handleInteraction = this.handleInteraction.bind(this);
    this.handleInView = this.handleInView.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.headerRef = React.createRef();
    this.scrollViewRef = React.createRef();
    this.handleLoad = this.handleLoad.bind(this);
    this.initOffsets = this.initOffsets.bind(this);
    this.hideAnnouncement = this.hideAnnouncement.bind(this);
    this.pageHasAnnouncement = this.pageHasAnnouncement.bind(this);
    if(isBrowser()){
      window.lineupTimeout = null;
    }
  }

  componentDidMount() {
    onReady(this.handleLoad);
    onReady(this.initOffsets);
    onReady(this.setUserSessionData);
    setTimeout(() => {
      DFPManager.load();
    }, 5000);
  }

  initOffsets() {
    this.determineOffsets();

    window.addEventListener('resize', this.determineOffsets);
  }

  componentWillUnmount() {
    if (isBrowser()) {
      window.removeEventListener('resize', this.determineOffsets);
    }

    onReadyUnmount(this.handleLoad);
    onReadyUnmount(this.initOffsets);
    onReadyUnmount(this.setUserSessionData);
  }

  handleArtistFilter = (selectedOption) => {
    window.history.pushState(null, '', `/lineup/`);

    this.handleInteraction();

    this.setState({ selectedOption });
  };

  determineOffsets() {
    let artistOffset = 0;
    let lineupOffset = 0;

    const isMobileViewport = isMobile();

    if (isBrowser()) {
      lineupOffset = (window.innerHeight / 2) * -1;
    }

    this.setState({
      lineupOffset,
      artistOffset,
    });
  }

  handleInteraction() {
    this.setState({
      hasInteracted: true,
    });
  }
  getBaseUrl(){
    let base = `/`;
    if(this.props.content?.language?.code && this.props.content?.language?.code !== 'EN') {
      base = `/${this.props.content.language.code.toLowerCase()}/`;
    }
    return `${base}lineup/`;
  }
  open() {
    this.setState({
      hasInteracted: true,
      isOpen: true,
    });
  }

  close() {
    window.history.pushState(null, '', getBaseUrl());

    this.setState({
      hasInteracted: true,
      isOpen: false,
    });
  }

  handleInView(inView) {
    if (inView) {
      window.history.pushState(null, '', getBaseUrl());

      const artistLinks = document.getElementsByClassName('lineup-link-active');

      Array.prototype.forEach.call(artistLinks, (artistLink) => {
        artistLink.classList.remove('lineup-link-active');
      });
    }
  }

  async setUserSessionData() {
    const token = getLocalStorage('token');
    const user = getSessionStorage('user');

    if (token && !user) {
      const { data } = await API.getContact({ token });
      setSessionStorage('user', JSON.stringify(data));
    }
  }

  renderChildren() {
    const {
      content, artists: artistList,
      context,
    } = this.props;

    if (content && artistList) {
      const { artists, selectedOption, hasInteracted, showModal } = this.state;
      const selectedArtistWordpressId = context?.selectedArtist ? context.selectedArtist.id : null;

      const filteredArtists = artists.map((artist) => {
        if(artist.menuOrder === null){
          artist.menuOrder = 0
        }
        return artist;
      }).sort(( a, b ) => {
        if ( a.menuOrder < b.menuOrder ){
          return -1;
        }
        if ( a.menuOrder > b.menuOrder ){
          return 1;
        }
        return 0;
      });
      return (
        <>
          <FixedSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()}>
            <SideBySide.Header ref={this.headerRef}>
              <Filter.ToggleButtonsHeader
                title={content.title}
                options={Object.entries(TOGGLE_OPTIONS).map(([, value]) => value)}
                selectedOption={selectedOption}
                onChange={this.handleArtistFilter}
              />
            </SideBySide.Header>
            <HeroAd />
            <SideBySide.Container>
              <SideBySide.Left close={this.close} isOpen={this.state.isOpen} id="artists-container">
                <ArtistDetailsSection>
                  {filteredArtists.map(
                    (artist: { id: string; title: string; uri: string; featuredImage: Image; data: any }, index) => (
                      <div key={index} id={generateArtistId(artist.id)}>
                        <Artist
                          {...artist}
                          lineupOffset={this.state.lineupOffset}
                          artistOffset={this.state.artistOffset}
                          containerId="artists-container"
                          selected={!hasInteracted && artist.id === selectedArtistWordpressId}
                          timeout={this.state.timeout}
                          getBaseUrl={() => this.getBaseUrl()}
                        />
                      </div>
                    ),
                  )}
                </ArtistDetailsSection>
              </SideBySide.Left>
              <SideBySide.Right id="links-container">
                <LineupSection>
                  <Lineup
                    artists={filteredArtists}
                    align="left"
                    artistOffset={this.state.artistOffset}
                    containerId="artists-container"
                    getBaseUrl={() => this.getBaseUrl()}
                    onArtistClick={() => {
                      this.open();
                      this.handleInteraction();
                    }}
                  />
                </LineupSection>
              </SideBySide.Right>
            </SideBySide.Container>
          </FixedSection>
        </>
      );
    }
    return <div />;
  }
}

export default LineupPage;
