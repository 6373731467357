import styled from 'styled-components';

export const ArtistDetailsSection = styled.div`
  position: relative;
`;

export const LineupSection = styled.div(
  ({ theme: { mobileView, spacing } }) => `
  padding-top: ${spacing.x6};
  padding-left: ${spacing.x3};
  padding-bottom: ${spacing.x16};
  margin-bottom: ${spacing.x16};

  ${mobileView} {
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 0;
  }
`,
);
